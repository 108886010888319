.AppMain {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin: 0 auto;
    width: 100%;
}

#root {
    width: 100%;
}

// .media-feed {
//     position: absolute;
//     right: 0;
//     padding: 50px;
// }

body {
    margin: 0 auto;
}

a:hover {
font-weight: bold;
color: #401F34;
}

a:active {
color: hotpink;
}

hr.line-break {
    border: 1px solid #25B8C6;
    margin-top: 30px;
    margin-bottom: 30px;

}

.logo-title {
    font-weight: 200;
    color: #25B8C6;
    font-size: 300;
}
.class-title {
    color: #25B8C6;
}
.class-description {
    color: #504f4f;
}

.side-nav {
    border:#401F34 solid 1px;
    border-radius: 2%;
    width:80%;
    margin: 10px;
    text-align: center;
}

.contact-jumbotron {
    text-align: center;
    border-radius: 2%;
}

.list-group-item {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.list-group-item:hover {
    color:#ffffff !important;
    font-size: 20px;
    background-color: #401F34 !important;
}

.classes-header, .pricing-header {
    margin-top: 30px;
}

.logo-home {
    width: 100%;
}

.about-us-description {
    word-wrap: break-word;
    text-align: start;
    padding: 10px;
}

.send-message-button {
    margin-bottom: 20px;
}

@media only screen and (min-width: 600px) {
    .about-us-block {
        display: flex;
    }
    
    .about-us-text {
        padding-left: 30px;
    }
    
    .about-us-image {
        max-width: 300px;
        height: auto;
    }
  }
@media only screen and (max-width: 600px) {
    .about-us-image {
        width: 80%;
        height: auto;
        padding-bottom: 20px;
    }
    .about-us-block {
        text-align: center;
    }
  }

.scroll-button {
    position: fixed;
  bottom: 15px;
  right: 15px;
}
.top {
    position: fixed;
  bottom: 15px;
  right: 15px;
}

.copyright-container {
    display: flex;
    justify-content: end;
}

.facebook-icon {
    padding-right: 20px;
}

$theme-colors: (
    "info": #25B8C6,
    "danger": teal,
    "primary": #401F34,
);

@import '~bootstrap/scss/bootstrap.scss';